.navbar-container {
  padding: 8px 0;
  .nav-item {
    padding-right: 15px;
    .nav-link {
      color: #fff;
      padding: 5px 15px;
      border-radius: 6px;
      font-weight: 600;
      &:hover,
      &.active {
        background-color: #b56448;
      }
    }
  }
}

.navbar-container-2 {
  padding: 8px 0;
  .nav-item {
    padding-right: 15px;
    .nav-link {
      color: #b56448;
      padding: 5px 15px;
      border-radius: 6px;
      font-weight: 600;
      &:hover,
      &:focus,
      &.active {
        color: #fff !important;
      }
    }
  }
}
