.exclusion-cont {
  .exclusion-title {
    font-size: 0.9rem !important;
    padding-bottom: 5px;
    font-weight: bold !important;
  }

  [class*='MuiInputBase-root'] {
    padding-top: 8px !important;
    min-height: 70px;
  }
  [class*='MuiFormControl-root'] {
    width: 100%;
  }

  [class*='MuiOutlinedInput-root'] {
    width: 100%;
  }

  .MuiAccordionDetails-root {
    padding: 25px !important;
  }
}
