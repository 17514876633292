body {
  height: 100vh;
  background-color: $light-color !important;
}

a:focus,
button:focus {
  box-shadow: unset !important;
}

.btn:hover {
  opacity: 0.7;
}

.icon-hover,
.icon-hover:hover,
.box,
.box:hover,
.box:focus,
.box.active {
  transition: all 0.3s ease;
  display: block;
}

.custom-btn {
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-weight: 600;
  font-size: 11px;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  border: 0;
  box-shadow: 0px 3px 1px -2px #00000033;
}

.btn-primary-load {
  background-color: #e9ecef;

  color: $text-color;
  &.active,
  &:focus,
  &:hover {
    background-color: $primary-color;
    color: $white-color;
  }
}

.icon-hover:hover {
  transform: scale(1.1);
}

.box {
  // color: $text-color !important;

  span {
    padding: 11px;
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px;
    height: 18px;
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
  }
}

.box:hover {
  box-shadow: 0 0 0 3px $primary-color !important;
}

// .font-small {
//   font-size: 13px;
// }

.text-underline {
  text-decoration: underline;
}

label {
  input[type='button'],
  input[type='file'] {
    display: none;
  }
}

// .label-font {
//   font-size: 15px;
// }

.apexcharts-line {
  stroke-width: 3px;
}

.apexcharts-bar-area {
  stroke-width: 0;
}

.legend-marker,
.apexcharts-legend-marker {
  display: flex;
  height: 10px !important;
  width: 17px !important;
  border-radius: 12px;
}

// table {
//   th {
//     position: sticky;
//     top: 0;
//     background-color: $white-color;
//     z-index: 30;
//     text-transform: capitalize;
//   }
//   th,
//   td {
//     text-align: center !important;
//   }
// }

.material-table-container {
  .MuiPaper-elevation2 {
    box-shadow: unset;
  }
}

.customBox {
  box-shadow: 0 0.125rem 0.25rem #00000013;
  border-radius: 15px;
}

.sourceSummaryBox {
  .MuiSvgIcon-root {
    width: 18px !important;
    height: 18px !important;
  }
}

.menu-icon {
  height: 30px !important;
  width: 30px !important;
  color: $white-color;
}

.icon-big {
  height: 25px !important;
  width: 25px !important;
}

.cursor-pointer {
  cursor: pointer;
}

// @media screen and (min-width: 1280px) {
//   .MuiContainer-maxWidthLg {
//     max-width: 1370px !important;
//   }
// }

.text-white-2 {
  color: $white-color !important;
}

.tab-btn-1 {
  width: 80%;
  border-radius: 7px;
  font-size: 17px;
  font-weight: 500;
  padding: 10px;

  &.active,
  &:hover {
    background-color: #fff;
    box-shadow: 0 0.125rem 0.25rem #00000013;
  }
}

a {
  &:hover {
    text-decoration: none;
  }
}
