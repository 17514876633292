@import '../../../styles/colors.scss';

.part-map-upload-dialog-cont {
  border-radius: 2px;
  padding-bottom: 0px;
  height: 150px;
  width: 100%;
  padding-top: 0px;
  padding-left: 0px;
}

.part-map-upload-filename-cont {
  margin-bottom: 2px;
  margin-left: 2px;
  .part-map-upload-filename {
    margin-left: 5px;
    color: $text-color;
    font-size: 0.9rem;
  }
}

.MuiDialog-paper {
  border-radius: 15px !important;
}
.part-map-upload-information {
  color: $text-color;
}
