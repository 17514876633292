.scroll-box-auto {
  overflow: auto;
}

.scroll-box-auto::-webkit-scrollbar {
  background-color: transparent;
  width: 10px;
}

.scroll-box-auto::-webkit-scrollbar-thumb {
  background-color: #c4c6c5;
}

.scroll-box {
  overflow-y: scroll;
}

.scroll-box::-webkit-scrollbar {
  background-color: transparent;
  width: 5px;
  opacity: 0;
}

.scroll-box:hover::-webkit-scrollbar {
  opacity: 1;
}

.scroll-box:hover::-webkit-scrollbar-thumb {
  background-color: #c4c6c5;
}

@media (hover: none) {
  .scroll-box-auto::-webkit-scrollbar {
    background-color: transparent;
    width: 5px;
  }

  .scroll-box::-webkit-scrollbar {
    width: 5px !important;
  }
  .scroll-box::-webkit-scrollbar-thumb {
    background-color: unset;
  }
}
