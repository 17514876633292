@import '../styles/colors';

.text-hover:hover {
  text-decoration: underline;
}
.MuiPopover-paper {
  border-radius: 12px !important;
}
.popup-container {
  border-radius: 12px;
}
.popup-header {
  border-bottom: 2px solid $primary-color;
  padding: 10px;
  margin-left: 20px;
  margin-right: 20px;
  p {
    font-weight: bold;
    margin-bottom: 0px;
  }
}
.popup-info {
  padding: 10px;
  margin-left: 20px;
  margin-right: 20px;
}

.popup-table-table {
  //border: none;
  margin-left: 20px;
  th {
    font-weight: normal;
    width: 20%;
  }
}

.popup-table-header {
  text-align: center;
}
.popup-table-value {
  text-align: center;
}

.MuiFormControl-root .MuiOutlinedInput-input {
  padding: 12px 10px !important;
}

.MuiTableBody-root {
  tr td:nth-child(4) {
    padding: 2px;
  }
}