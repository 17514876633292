/* make the customizations */
$theme-colors: (
  'primary': #f37e57,
  'secondary': #8f8bff,
  'danger': #bc3c3d,
  'success': #b1eab3,
  'dark': #5a6268,
  'light': #f2f4f5,
  'info': #1b114d,
);

$primary-color: #f37e57;
$light-color: #f2f4f5;
$text-color: #7d7e7e;
$white-color: #fff;
$darkgrey-color: #303030;

.btn-primary {
  color: $white-color !important;
}

.bg-1 {
  background-color: #b56448;
}

.dark-grey {
  color: $darkgrey-color;
}
